import createStore from "zustand";

export type DirectionStepStore = {
  // state
  step: number;
  data: any;

  // actions
  setStep: (b: number) => void;
  setData: (b: any) => void;
};

export const useDirectionStepStore = createStore<DirectionStepStore>((set) => ({
  // state
  step: 1,
  data: {
    name: "",
    village: "",
  },

  // actions
  setStep(num) {
    set({ step: num });
  },
  setData(item) {
    set({ data: item });
  },
}));
