import { useEffect, useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FieldText from "components/Form/FieldText";
import Button from "components/Button";
import { useAuthSetLogin, useRedirectPreviousPath } from "./authHooks";
import { useRouter } from "next/router";
import { useAuthStore } from "./authStore";
import { useUserGetProfile } from "modules/User/userApi";
import useTranslation from "next-translate/useTranslation";
import { isBrowser } from "lib/ssr";
import { getSubDomain, useIsSubdomain, useIsTenant } from "lib/host";
import { AuthSocialButtonNew } from "./AuthSocialButtonNew";
import {
  useAuthAccountApi,
  useAuthMoodleApi,
  useAuthV2LoginApi,
} from "./authApi";
import { useToastStore } from "components/Toast/toastStore";
import Autocomplete from "react-autocomplete";
import Modal from "components/Modal";
import { useDirectionStepStore } from "modules/Nav/directionStepStore";
import { BackButton } from "components/PageHeader/BackButton";
import { deleteCookie, getCookie, parseTokenSha1, setCookie } from "lib/helper";
import { useAccountUpdateProfileApi } from "modules/Account/accountApi";
import { useGetVillages } from "modules/Tenant/homeTenantApi";
import InputBase, { style as inputBaseStyle } from "components/Form/InputBase";
import { IconCheck } from "components/Icon/IconCheck";
import { IconWhatsapp } from "components/Icon/IconWhatsapp";
import { CONST } from "lib/const";

// Ask id province from backend (add by hard code in frontend)
const ProvinceID = [
  {
    tenant: "pesawarankab",
    id: 18,
    regency_id: 1809,
  },
  {
    tenant: "trenggalekkab",
    id: 35,
    regency_id: 3503,
  },
  {
    tenant: "maluku-gate",
    id: 81,
    regency_id: 8102,
  },
  {
    tenant: "muarojambikab",
    id: 15,
    regency_id: 1505,
  },
  {
    tenant: "jambikota",
    id: 15,
    regency_id: 1571,
  },
];

export const b2cSubdomain = ["www", "localhost"];
// For Apple submission purpose (25 June 2024)
// export const listSubdomainNoGoogleSignIn = ['gate','ares-gate']
export const listSubdomainNoGoogleSignIn = ["gate"];

// TODO: Check the usage since make error
// Hydration failed because the initial UI does not match what was rendered on the server.
export const AuthProvinceLoginApp: React.FC<{
  useRegister?: boolean;
  domainName?: string;
  useFieldOrganization?: boolean;
  ip_address?: string;
  userAgent?: string;
}> = ({
  useRegister = true,
  domainName = "",
  useFieldOrganization = false,
  ...props
}) => {
  const [isShowForgot, showForgot] = useState(false);
  const [isShowVerify, showVerify] = useState(false);
  const [step, setStep] = useState(1);
  const [currentLogin, setCurrentLogin] = useState({});
  const [totalErrorPwd, setTotalError] = useState(0);

  const LoginSchema: any = z.object({
    username: z.string().min(1, { message: "Masukkan email/no.hp kamu" }),
    password: z.string().min(8, { message: "Kata sandi minimal 8 karakter" }),
  });

  type FormData = z.infer<typeof LoginSchema>;

  const hookLoginForm = useForm<FormData>({
    resolver: zodResolver(LoginSchema),
  });

  return (
    <div className="w-full">
      {
        {
          1: (
            <FormLogin
              setCurrentLogin={setCurrentLogin}
              showForgot={showForgot}
              domainName={domainName}
              showVerify={showVerify}
              setTotalError={setTotalError}
              totalErrorPwd={totalErrorPwd}
              handleUpdateStep={setStep}
            />
          ),
        }[step]
      }
    </div>
  );
};

export const FormLogin: React.FC<{
  showForgot?: any;
  setCurrentLogin?: any;
  handleUpdateStep?: any;
  domainName?: any;
  setTotalError?: any;
  totalErrorPwd?: any;
  showVerify?: any;
}> = () => {
  const router = useRouter();
  const { t } = useTranslation("common");

  const [currentToken, setCurrentToken] = useState("");
  const [village, setVillage] = useState("");
  const [messageError, setMessageError] = useState(null);
  const [villages, setVillages] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep, data, setData] = useDirectionStepStore((state) => [
    state.step,
    state.setStep,
    state.data,
    state.setData,
  ]);
  const setLogin = useAuthStore((state) => state.setLogin);

  const authLoginRegisterApi = useAuthMoodleApi();
  const authSetLogin = useAuthSetLogin();
  const authStore = useAuthStore();
  const userGetProfile = useUserGetProfile();
  const accountUpdateProfile = useAccountUpdateProfileApi();
  const currentUser = useAuthStore((state) => state.currentUser);

  const LoginSchema: any =
    step == 1
      ? z.object({
          username: z
            .string()
            .min(1, { message: "Masukkan email/no.hp kamu" })
            .refine(
              (value) => {
                const isPhoneValid = /^[0-9]{10,15}$/.test(value);
                const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                return isPhoneValid || isEmailValid;
              },
              {
                message: "Mohon masukkan No.HP / Email yang benar.",
              }
            ),
        })
      : step == 2
      ? z.object({
          name: z.string().min(1, { message: "Masukkan nama kamu" }),
        })
      : z.object({
          village: z.string().min(1, { message: "Masukkan nama desa kamu" }),
        });
  type FormData = z.infer<typeof LoginSchema>;
  const hookLoginForm = useForm<FormData>({
    resolver: zodResolver({ ...LoginSchema, mode: "onChange" }),
  });

  const { handleSubmit, watch } = hookLoginForm;
  const subDomain = isBrowser() ? getSubDomain(window.location.hostname) : null;

  const referralCode =
    getCookie("ref_code_gk") && getCookie("ref_code_gk") !== "null"
      ? getCookie("ref_code_gk")
      : typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("referral")
      : "";

  useEffect(() => {
    if (watch("name")) {
      setData({ ...data, name: watch("name") });
    } else {
      setData({ ...data, name: "" });
    }
  }, [watch("name")]);

  // STEP 1 : login/register user
  const loginRegisterUser = (data: FormData) => {
    let user = null;
    let userSplit = data?.username.split("");
    if (userSplit[0] === "6" && userSplit[1] === "2") {
      user = data?.username.replace("62", "0");
    } else {
      user = data?.username;
    }
    const tokenGenerated = parseTokenSha1(
      `${user}|4D6351655468576D5A7134743777217A25432A462D4A614E645267556A586E32`
    );
    const dataForm = !user.includes("08")
      ? {
          referral:
            // referralCode == "trenggalekkab" ? referralCode
            // :
            subDomain,
          email: user,
          name: "",
          token: tokenGenerated,
        }
      : {
          referral:
            // referralCode == "trenggalekkab" ? referralCode
            //  :
            subDomain,
          phone: user,
          name: "",
          token: tokenGenerated,
        };

    // @ts-ignore
    authLoginRegisterApi.doFetch(dataForm, {
      onSuccess(result: any) {
        if (result.is_register) {
          setCurrentToken(result?.token);
          getVillages();
          setStep(step + 1);
        } else {
          localStorage.setItem("token", result.token);
          setCookie("token", currentToken, 7, "domain=gokampus.com");
          userGetProfile.doFetch(
            {},
            {
              onSuccess(res: any) {
                if (res?.data?.name) {
                  authStore.setLogin(res.data);
                  router.push("/");
                  setStep(1);
                } else {
                  setCurrentToken(result?.token);
                  getVillages();
                  setStep(step + 1);
                }
              },
            }
          );
        }
      },
      onError(err) {
        const error = err.getActualType();
        if ([409, 404, 403].includes(error?.status)) {
          hookLoginForm.setError("username", {
            message: error.data?.message,
          });
        }
      },
    });
  };

  // STEP 3 : update profile if new registration
  const updateProfile = (f: FormData) => {
    setData({ ...data, name: f?.name });
    setStep(step + 1);
  };

  // STEP 4 : update profile village if new registration
  const updateProfileVillage = () => {
    if (!data.village) {
      setMessageError("Masukkan nama desa kamu");
    } else {
      localStorage.setItem("token", currentToken);
      setCookie("token", currentToken, 7, "domain=gokampus.com");

      accountUpdateProfile.doFetch(data, {
        onSuccess(res: any) {
          authStore.setLogin(res.data);
          router.push("/");
          setStep(1);
        },
        onError(err) {
          const error = err.getActualType();
          if ([409, 404, 403].includes(error?.status)) {
            hookLoginForm.setError("village", {
              message: error.data?.message,
            });
          }
        },
      });
    }
  };

  const villageApi = useGetVillages();
  const getVillages = () => {
    villageApi.doFetch(
      {
        slug:
          window.location.hostname === "e-learning.bluebirdgroup.com"
            ? "bluebird"
            : // : referralCode == "trenggalekkab"
              // ? referralCode
              subDomain,
        province:
          // referralCode == "trenggalekkab"
          //   ? ProvinceID.find((z) => z.tenant == referralCode)?.id
          //   :
          ProvinceID.find((z) => z.tenant == subDomain)?.id,
      },
      {
        onSuccess(data: any) {
          const regency_id =
            // referralCode == "trenggalekkab"
            //   ? ProvinceID.find((z) => z.tenant == referralCode)?.regency_id
            //   :
            ProvinceID.find((z) => z.tenant == subDomain)?.regency_id;

          // if (referralCode == "trenggalekkab") {
          //   if (
          //     ProvinceID.find((z) => z.tenant == referralCode)?.id == 35 ||
          //     ProvinceID.find((z) => z.tenant == referralCode)?.id == 18 ||
          //     ProvinceID.find((z) => z.tenant == referralCode)?.id == 81 ||
          //     ProvinceID.find((z) => z.tenant == referralCode)?.id == 15
          //   ) {
          //     setVillages(
          //       data.data.filter((a) => a.district.regency.id == regency_id)
          //     );
          //   } else {
          //     setVillages(data.data);
          //   }
          // } else {
          if (
            ProvinceID.find((z) => z.tenant == subDomain)?.id == 35 ||
            ProvinceID.find((z) => z.tenant == subDomain)?.id == 18 ||
            ProvinceID.find((z) => z.tenant == subDomain)?.id == 81 ||
            ProvinceID.find((z) => z.tenant == subDomain)?.id == 15
          ) {
            setVillages(
              data.data.filter((a) => a.district.regency.id == regency_id)
            );
          } else {
            setVillages(data.data);
          }
          // }
        },
      }
    );
  };

  return (
    <div className="">
      {step === 1 && (
        <>
          <div className="text-neutral-80">
            <p className="font-bold text-h4">Akses Kelas & Raih Sertifikat!</p>
            <span className="font-light text-b2">
              Masuk untuk mulai belajar.
            </span>
          </div>

          {/* {listSubdomainNoGoogleSignIn.includes(subDomain) ? (
            <></>
          ) : (
            <div className="w-full flex justify-center mt-24">
              <AuthSocialButtonNew
                trackerId="signup_google_a"
                className="w-full"
              />
            </div>
          )} */}
        </>
      )}
      <div>
        {step === 1 && (
          <div className="mt-28">
            <form onSubmit={handleSubmit(loginRegisterUser)}>
              <FieldText
                name="username"
                label="Masukkan Nomor WhatsApp"
                placeholder="08xxx"
                iconBeforeInput={<IconWhatsapp size={24} />}
                inputStyle="!pl-42"
                validator={{
                  hook: hookLoginForm,
                }}
              />

              <div className="mt-16">
                <div className="mb-16">
                  <Button
                    type="submit"
                    size="large"
                    isFull
                    state={
                      authLoginRegisterApi.isLoading ? "loading" : "active"
                    }
                  >
                    Oke
                  </Button>
                </div>
              </div>
            </form>

            {listSubdomainNoGoogleSignIn.includes(subDomain) ? (
              <></>
            ) : (
              <div className="w-full flex justify-center mt-24">
                {/* <AuthSocialButtonNew
                  trackerId="signup_google_a"
                  className="w-full"
                /> */}
              </div>
            )}
          </div>
        )}

        {step === 2 && (
          <form onSubmit={handleSubmit(updateProfile)}>
            <p className="text-b2 font-bold text-purple-30 mb-16">
              Step {step - 1}/2
            </p>
            <FieldText
              name="name"
              label="Masukkan Nama Anda"
              additionalInfo="Nama ini akan dicantumkan pada sertifikat"
              placeholder="Ketik nama anda disini."
              validator={{
                hook: hookLoginForm,
              }}
            />
            <div className="mt-16">
              <div className="mb-16">
                <Button
                  type="submit"
                  size="large"
                  isFull
                  state={accountUpdateProfile.isLoading ? "loading" : "active"}
                >
                  Oke
                </Button>
              </div>
            </div>
          </form>
        )}

        {step === 3 && (
          <div>
            <div className="flex flex-col md:hidden">
              <p className="text-b2 font-bold text-purple-30 mb-16">
                Step {step - 1}/2
              </p>
              <FieldText
                name="village"
                label="Apa nama desa Anda?"
                placeholder="Ketik nama desa atau pilih opsi"
                value={village}
                onFocus={() => setIsShow(true)}
                messageError={messageError}
                // validator={{
                //   hook: hookLoginForm,
                // }}
              />
              <div className="mt-16">
                <div className="mb-16">
                  <Button
                    size="large"
                    // type="submit"
                    isFull
                    onClick={updateProfileVillage}
                    state={
                      accountUpdateProfile.isLoading ? "loading" : "active"
                    }
                  >
                    Mulai Belajar
                  </Button>
                </div>
              </div>
            </div>

            <div className="hidden md:flex md:flex-col">
              <p className="text-b2 font-bold text-purple-30 mb-16">
                Step {step - 1}/2
              </p>
              {/* <FieldText
                name="village"
                label="Apa nama desa Anda?"
                value={village}
                onFocus={() => setIsOpen(true)}
                messageError={messageError}
                // validator={{
                //   hook: hookLoginForm,
                // }}
              /> */}

              <div className="mb-8">
                <label className="text-b1 text-neutral-80 font-medium mb-0 block flex gap-4">
                  Apa nama desa Anda?
                </label>
              </div>

              <div className="autocomplete-wrapper-desktop">
                <Autocomplete
                  value={village}
                  items={villages.sort(function (a, b) {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })}
                  getItemValue={(item) =>
                    `${item.name}, ${item.district.name}, ${item.district.regency.name}`
                  }
                  shouldItemRender={(s, val) =>
                    s.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
                  }
                  renderMenu={(item) => <div className="dropdown">{item}</div>}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${isHighlighted ? "selected-item" : ""}`}
                    >
                      {item.name}, {item.district.name},{" "}
                      {item.district.regency.name}
                      {isHighlighted && (
                        <div className="absolute top-0 right-0 w-full h-full rounded-8 flex justify-end">
                          {/* <div className="w-24 h-24 bg-purple-50 mr-0 rounded-lg flex justify-center items-center">
                            <IconCheck color="#fff" />
                          </div> */}
                        </div>
                      )}
                    </div>
                  )}
                  wrapperStyle={{ style: "width:100%" }}
                  renderInput={(props) => (
                    <div className="flex gap-8 items-center py-14">
                      <input
                        className="text-neutral-80 w-full px-8 h-40 text-b1 border bg-field_input border-neutral40_to_dark20 rounded-4 placeholder-input_placeholder placeholder:font-light w-[-webkit-fill-available]"
                        autoFocus={isOpen}
                        onFocus={() => setIsOpen(true)}
                        placeholder="Ketik nama desa atau pilih opsi"
                        {...props}
                      />
                    </div>
                  )}
                  onChange={(e) => {
                    setVillage(e.target.value);

                    if (!e.target.value) {
                      setData({
                        ...data,
                        village: "",
                      });
                    }
                  }}
                  onSelect={(val) => {
                    const splittedValue = val.split(",")[0];
                    setVillage(val);

                    setData({
                      ...data,
                      village: villages.find((a) => a.name === splittedValue)
                        ?.id,
                    });
                    // setIsShow(false);
                  }}
                />
              </div>

              <div className="mt-16">
                <div className="mb-16">
                  <Button
                    size="large"
                    // type="submit"
                    isFull
                    onClick={updateProfileVillage}
                    state={
                      accountUpdateProfile.isLoading ? "loading" : "active"
                    }
                  >
                    Mulai Belajar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <Modal
          isShow={isShow}
          className="!bottom-auto"
          contentClassName="!rounded-8"
        >
          <div className="w-full h-screen">
            <div className="autocomplete-wrapper">
              <Autocomplete
                value={village}
                items={villages.sort(function (a, b) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })}
                open
                getItemValue={(item) =>
                  `${item.name}, ${item.district.name}, ${item.district.regency.name}`
                }
                shouldItemRender={(s, val) =>
                  s.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
                }
                renderMenu={(item) => <div className="dropdown">{item}</div>}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item`}
                    // className={`item ${isHighlighted ? "selected-item" : ""}`}
                  >
                    {item.name}, {item.district.name},{" "}
                    {item.district.regency.name}
                    {isHighlighted && (
                      <div className="absolute top-0 right-0 w-full h-full rounded-8 flex justify-end">
                        {/* <div className="w-24 h-24 bg-purple-50 mr-0 rounded-tr-lg rounded-bl-8 flex justify-center items-center">
                          <IconCheck color="#fff" />
                        </div> */}
                      </div>
                    )}
                  </div>
                )}
                wrapperStyle={{ style: "width:100%" }}
                renderInput={(props) => (
                  <div className="flex gap-8 items-center py-14 border-b border-neutral-40">
                    <BackButton backTo={() => setIsShow(false)} />
                    <input
                      className="text-neutral-80 bg-transparent text-b1  placeholder-input_placeholder placeholder:font-light w-[-webkit-fill-available]"
                      autoFocus
                      placeholder="Ketik nama desa atau pilih opsi"
                      {...props}
                    />
                  </div>
                )}
                onChange={(e) => {
                  setVillage(e.target.value);

                  if (!e.target.value) {
                    setData({
                      ...data,
                      village: "",
                    });
                  }
                }}
                onSelect={(val) => {
                  const splittedValue = val.split(",")[0];
                  setVillage(val);

                  setData({
                    ...data,
                    village: villages.find((a) => a.name === splittedValue)?.id,
                  });
                  setIsShow(false);
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
