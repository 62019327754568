import { useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import FieldText from "components/Form/FieldText";
import FieldPassword from "components/Form/FieldPassword";
import Button from "components/Button";
import { AuthForgotPassword } from "./AuthForgotPassword";
import { useAuthV2LoginApi } from "./authApi";
import { useAuthSetLogin, useRedirectPreviousPath } from "./authHooks";
import Link from "next/link";
import { trackEvent, useTrackScreen } from "lib/amplitude";
import { AuthVerifyReset } from "./AuthVerifyReset";
import { AuthSocialButtonNew } from "./AuthSocialButtonNew";
import { useRouter } from "next/router";
import { useAuthStore } from "./authStore";
import { useUserGetProfile } from "modules/User/userApi";
import {
  getSubDomain,
  isGateURL,
  isMalukuURL,
  isPesawaranURL,
  useIsSubdomain,
  useIsTenant,
} from "lib/host";
import { getCookie, setCookie } from "lib/helper";
import useTranslation from "next-translate/useTranslation";
import { isBrowser } from "lib/ssr";
import { CONST } from "lib/const";
import { AuthLoginApp } from "./AuthLoginApp";
import { AuthProvinceLoginApp } from "./AuthProvinceLoginApp";
import { BottomNav } from "modules/Nav/BottomNav";

// TODO: Check the usage since make error
// Hydration failed because the initial UI does not match what was rendered on the server.
export const AuthLoginForm: React.FC<{
  useRegister?: boolean;
  domainName?: string;
  ip_address?: string;
  userAgent?: string;
}> = ({
  useRegister = true,
  domainName = "",
  ip_address = "",
  userAgent = "",
}) => {
  const router = useRouter();
  const { redirect_uri } = router.query;

  const [isShowForgot, showForgot] = useState(false);
  const [isShowVerify, showVerify] = useState(false);
  const [totalErrorPwd, setTotalError] = useState(0);
  const isSubdomainTenant = useIsSubdomain();

  const authLoginApi = useAuthV2LoginApi();
  const authStore = useAuthStore();
  const userGetProfile = useUserGetProfile();

  // useTrackScreen({ event: "signin_s" });

  const { encodedPath } = useRedirectPreviousPath();

  const subDomain = isBrowser() ? getSubDomain(window.location.hostname) : null;
  const slug: string = CONST.IS_DEV ? "ares-gate" : "gate";

  const LoginSchema: any =
    subDomain !== slug
      ? z.object({
          username: z.string().min(1, { message: "Masukkan email/no.hp kamu" }),
          password: z
            .string()
            .min(8, { message: "Kata sandi minimal 8 karakter" }),
        })
      : z.object({
          username: z.string().min(1, { message: "Masukkan email/no.hp kamu" }),
          password: z
            .string()
            .min(8, { message: "Kata sandi minimal 8 karakter" }),
          referral: z.string().min(1, { message: "Masukkan referral kamu" }),
        });

  type FormData = z.infer<typeof LoginSchema>;

  const hookForm = useForm<FormData>({
    resolver: zodResolver(LoginSchema),
  });

  const { handleSubmit } = hookForm;

  const authSetLogin = useAuthSetLogin();
  const isB2B = useIsTenant() || useIsSubdomain();

  /**
   * onSubmit
   */
  const onSubmit = (data: FormData) => {
    let origin = "reguler";
    if (isB2B) {
      origin = "business";
    }

    trackEvent({ event: "signin_a", property: { is_success: undefined } });

    let body = {
      username: data.username,
      password: data.password,
      origin,
    };

    if (domainName && subDomain !== slug) {
      // should update object with 'referral' when subdomain customized,
      // if no have subdomain, it will login to gokampus's account
      (body as any).referral = domainName;
    } else if (subDomain === slug) {
      (body as any).referral = data.referral;
    }

    if (redirect_uri) {
      //@ts-ignore
      body.redirect_uri = redirect_uri;
      //@ts-ignore
      localStorage.setItem("redirect_uri", redirect_uri);
    }

    authLoginApi.doFetch(body, {
      onSuccess(data) {
        //@ts-ignore
        if (data.redirect_uri) {
          //@ts-ignore
          localStorage.setItem("token", data.access_token);
          //@ts-ignore
          setCookie("token", data.access_token, 7);
          authStore.setLoading();
          userGetProfile.doFetch(
            {},
            {
              onSuccess(res) {
                authStore.setLogin(res.data);

                //@ts-ignore
                const directURL = data.redirect_uri + "?code=" + data.code;
                //@ts-ignore
                router.replace(directURL);
              },
            }
          );
        } else {
          authSetLogin(
            //@ts-ignore
            data.access_token,
            "username",
            "signin_success_a",
            null,
            domainName ? "domain=gokampus.com" : null
          );
        }
      },
      onError(err) {
        if (err instanceof authLoginApi.fetcher.Error) {
          const error = err.getActualType();

          if (error.status == 401) {
            setTotalError(totalErrorPwd + 1);

            if (totalErrorPwd == 3) {
              showVerify(true);
            } else {
              hookForm.setError("password", {
                //@ts-ignore
                message: error.data?.message,
              });
            }
          } else if (error.status == 404 || 422) {
            // @ts-ignore
            if (error.data?.errors?.referral) {
              hookForm.setError("referral", {
                //@ts-ignore
                message: error.data?.message,
              });
            } else {
              hookForm.setError("username", {
                //@ts-ignore
                message: error.data?.message,
              });
            }
          }
        }
      },
    });
    // console.log('onSubmit', data);
  };

  const { t } = useTranslation("common");

  const isGate =
    typeof window !== "undefined" && isGateURL(window.location.hostname);

  /* Allow user to input Referral manually */
  const listDomainShowReferralFieldOnSignUp = ["zicare", "bluebird"];

  // only for maluku tenant
  const isMalukuTenant =
    typeof window !== "undefined" && isMalukuURL(window.location.hostname);

  // only for pesawaran tenant
  const isPesawaranTenant =
    typeof window !== "undefined" && isPesawaranURL(window.location.hostname);

  /* Tenant subdomain new login v3 */
  const listProvince = [
    "trenggalekkab",
    "pesawarankab",
    "muarojambikab",
    "jambikota",
  ];

  const referralCode =
    getCookie("ref_code_gk") && getCookie("ref_code_gk") !== "null"
      ? getCookie("ref_code_gk")
      : typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("referral")
      : "";

  return (
    <div
      className={
        "w-full flex flex-col items-start justify-center pb-16 " +
        (isGate ? "h-full" : "")
      }
    >
      {isMalukuTenant || listProvince.includes(subDomain) ? (
        // ||
        // referralCode == "trenggalekkab"
        <AuthProvinceLoginApp
          useFieldOrganization={
            subDomain === slug ||
            listDomainShowReferralFieldOnSignUp.includes(subDomain)
          }
          ip_address={ip_address}
          userAgent={userAgent}
        />
      ) : (
        <AuthLoginApp
          useFieldOrganization={
            subDomain === slug ||
            listDomainShowReferralFieldOnSignUp.includes(subDomain)
          }
          ip_address={ip_address}
          userAgent={userAgent}
        />
      )}
    </div>
  );
};
