import {
  useTrackScreen,
  useTrackScreenGA,
  useTrackScreenGTM,
} from "lib/amplitude";
import { useIsDesktop } from "lib/device";
import { AuthLayoutDesktop } from "modules/Auth/AuthLayoutDesktop";
import { GetServerSideProps } from "next";
import { AuthLoginForm } from "modules/Auth/AuthLoginForm";
import { getSubDomain } from "lib/host";
import { useAuthRedirectHomeWhenLogin } from "modules/Auth/authHooks";
import { SocialMeta } from "modules/Meta/SocialMeta";
import { isBrowser } from "lib/ssr";
import { checkTawkTo } from "lib/tawk-to";
import { useEffect } from "react";
import { useAuthStore } from "modules/Auth/authStore";
import { BottomDirection } from "modules/Nav/BottomDirection";
import { useDirectionStepStore } from "modules/Nav/directionStepStore";
import { getCookie } from "lib/helper";

export const getServerSideProps: GetServerSideProps = async (context) => {
  let ip: any;

  const { req } = context;

  const forwardedFor = req.headers["x-forwarded-for"];
  const userAgent = req.headers["user-agent"];

  if (forwardedFor) {
    if (Array.isArray(forwardedFor)) {
      ip = forwardedFor.at(0);
    } else {
      ip = forwardedFor?.split(",").at(0) ?? null;
    }
  } else if (req.headers["x-real-ip"]) {
    ip = req.connection.remoteAddress;
  } else {
    ip = req.connection.remoteAddress;
  }

  return {
    props: {
      ip,
      userAgent,
    },
  };
};

const Login: React.FC = (props) => {
  const isDesktop = useIsDesktop();
  useAuthRedirectHomeWhenLogin();
  //@ts-ignore
  const { ip, userAgent } = props;

  useTrackScreen({
    event: "signin_s",
  });
  useTrackScreenGTM({
    event: "signin_s",
  });
  useTrackScreenGA({
    event: "signin_s",
  });

  const subDomain = isBrowser() && getSubDomain(window.location.hostname);
  const currentUser = useAuthStore((state) => state.currentUser);
  const loginState = useAuthStore((state) => state.loginState);
  const step = useDirectionStepStore((state) => state.step);

  useEffect(() => {
    checkTawkTo(currentUser);
  }, []);

  const domain = typeof window !== "undefined" && window.location.hostname;
  const noRegisterSubdomain = ["interagroup", "arwanacitra", "smartdesa"];
  // const domain = typeof window !== "undefined" && window.location.hostname;
  // const noRegisterSubdomain = ["interagroup", "arwanacitra", "smartdesa"];

  const referralCode =
    getCookie("ref_code_gk") && getCookie("ref_code_gk") !== "null"
      ? getCookie("ref_code_gk")
      : typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("referral")
      : "";

  return (
    <div className="">
      <SocialMeta title={"Sign In - goKampus"} />

      <AuthLayoutDesktop>
        <AuthLoginForm
          useRegister={
            subDomain &&
            // || referralCode == "trenggalekkab"
            !noRegisterSubdomain.some((a) => subDomain.includes(a))
          }
          domainName={
            // referralCode == "trenggalekkab" ? referralCode :
            subDomain
          }
          // useRegister={!noRegisterSubdomain.some((a) => domain.includes(a))}
          // domainName={getSubDomain(domain)}
          ip_address={ip}
          userAgent={userAgent}
        />
      </AuthLayoutDesktop>
      {[2, 3].includes(step) && <BottomDirection />}
    </div>
  );
};

export default Login;
