import Button from "components/Button";
import { IconArrow } from "components/Icon/IconArrow";
import { IconDashboard } from "components/Icon/IconDashboard";
import { IconHome } from "components/Icon/IconHome";
import { IconPlayCircle } from "components/Icon/IconPlayCircle";
import { IconSearch } from "components/Icon/IconSearch";
import { SkeletonRect, SkeletonWrapper } from "components/Skeleton/Skeleton";
import { trackEvent } from "lib/amplitude";
import { useIsSubdomain, useIsTenant } from "lib/host";
import { ReduceArrayFromDuplicate } from "lib/object";
import { useAccountUpdateProfileApi } from "modules/Account/accountApi";
import { useAuthStore } from "modules/Auth/authStore";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDirectionStepStore } from "./directionStepStore";
import { AuthLogoMaluku } from "modules/Auth/AuthLogo";
import { useSystemStore } from "components/PageHeader/systemStore";

export const iconColor = {
  default: "#817E8C",
  selected: "#19093A",
};

const labelColor = {
  default: "text-neutral-50 text-c1 font-light text-center",
  selected: "text-purple-80 text-c1 font-bold text-center",
};

export const BottomDirection = () => {
  const loginState = useAuthStore((state) => state.loginState);
  const [step, data, setStep] = useDirectionStepStore((state) => [
    state.step,
    state.data,
    state.setStep,
  ]);

  const { t } = useTranslation("common");
  const accountUpdateProfile = useAccountUpdateProfileApi();
  const authStore = useAuthStore();
  const router = useRouter();
  const [theme] = useSystemStore((state) => [state.theme]);

  // STEP 4 : update profile village if new registration
  const updateProfileVillage = () => {
    accountUpdateProfile.doFetch(data, {
      onSuccess(res: any) {
        // localStorage.setItem("token", currentToken);
        // setCookie("token", currentToken, 7, "domain=gokampus.com");
        authStore.setLogin(res.data);
        setStep(1);
        router.push("/");
      },
    });
  };

  return (
    <>
      <nav className="flex sticky bottom-0 left-0 right-0 justify-center z-10 bg-neutral-10 md:hidden">
        {/* hide max-w-[720px] to make sure the background is full  */}
        <div className="relative w-full h-72 bg-white flex items-center justify-between px-16 gap-12 border-t border-neutral20_to_dark30">
          <Button
            size="large"
            variant={theme == "dark" ? "secondary_transparant" : "white"}
            onClick={() => setStep(1)}
            className="!font-medium !p-0 !border-0"
          >
            Halaman Awal
          </Button>

          <div className="flex gap-4">
            {/* prev */}
            <Button
              size="large"
              onClick={() => {
                if (step == 1) {
                  router.push("/");
                } else {
                  setStep(step - 1);
                }
              }}
              variant="secondary_transparant"
              className="!w-40 !p-0"
            >
              <IconArrow isCaret direction="up" color="#551FC1" />
            </Button>

            {/* next */}
            <Button
              size="large"
              onClick={
                step == 2 && !data.name
                  ? undefined
                  : step == 3 && !data.village
                  ? undefined
                  : step == 3
                  ? updateProfileVillage
                  : () => setStep(step + 1)
              }
              variant={"secondary_transparant"}
              state={
                accountUpdateProfile.isLoading
                  ? "loading"
                  : step == 2
                  ? !data.name
                    ? "disabled"
                    : "active"
                  : step == 3
                  ? !data.village
                    ? "disabled"
                    : "active"
                  : "active"
              }
              className="!w-40 !p-0"
            >
              <IconArrow
                isCaret
                direction="bottom"
                color={
                  step == 2 && !data.name
                    ? "#BEBCC4"
                    : step == 3 && !data.village
                    ? "#BEBCC4"
                    : step == 3
                    ? "#551FC1"
                    : "#551FC1"
                }
              />
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
};
